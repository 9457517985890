.side_menu svg {
  cursor: pointer;
  height: 44px;
  width: 44px;
}
.side_menu svg path {
  fill: #fff;
}
.side_menu ol {
  list-style-type: none;
}
.side_menu a[href] {
  color: #fff;
  position: relative;
  text-decoration: none;
}
.side_menu a[href]:hover:after {
  transform: scaleX(1);
}
.side_menu a[href]:after {
  content: '';
  position: absolute;
  top: 100%;
  height: 4px;
  background: #fff;
  left: 0;
  right: 0;
  transition: transform 0.15s;
  transform-origin: left;
  transform: scaleX(0);
}
.side_menu {
  margin: 0%;
  display: flex;
  background-color: rgb(33, 33, 34);
}

.side_menu > * {
  flex: 1 1 30%;
}

.search {
  margin: 0.5rem;
  margin-right: 1rem;
}

.search_panel {
  position: absolute;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1;
  right: 1rem;
  width: 47%;
  box-shadow: 0 4px 6px 4px rgba(0,0,0,0.2);
  margin: 0rem;
  padding: 0rem 0.75rem;
}

.search_panel p {
  margin: 0.75rem;
}

.menu {
  position: relative;
  width: 250px;
}
.menu__content {
  color: #fff;
  margin: 0;
  padding: 0 0 25px 0;
  position: absolute;
  right: 100%;
  top: 0;
  margin-top: 4rem;
  width: 20rem;
  z-index: 2;
}
.menu__toggle-label {
  height: 44px;
  left: 0;
  position: absolute;
  width: 44px;
}
.menu__toggle-label svg {
  left: 2rem;
  position: absolute;
  top: 7%;
  transition: transform 0.15s;
  z-index: 2;
}
.menu__toggle-label svg:nth-of-type(2) {
  left: 400px;
  transform: scale(0);
}
.menu__toggle {
  opacity: 0;
  position: fixed;
}
.menu__toggle:checked ~ .menu__toggle-label {
  background: rgba(0,0,0,0.9);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  margin-top: 0rem;
  transition: background 0.15s;
  width: 100vw;
  z-index: 2;
}
.menu__toggle:checked ~ .menu__toggle-label svg:nth-of-type(1) {
  transform: scale(0);
}
.menu__toggle:checked ~ .menu__toggle-label svg:nth-of-type(2) {
  top: 6rem;
  left: 300px;
  transform: scale(1);
  transition: transform 0.15s;
  transition-delay: 0.925s;
}
.menu__toggle:checked ~ .menu__content {
  transform: translate(100%, 0);
}
.menu__toggle:checked ~ .menu__content .menu-item {
  transform: translateX(0);
  transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275), border-color 0.15s;
}

.menu__toggle:checked ~ .menu__content .menu-item {
  border-color: #e26a6a;
}
.menu__toggle:checked ~ .menu__content .menu-item a[href]:after {
  background: #e26a6a;
}

.menu__content > .menu-item {
  border-left: 8px solid transparent;
  opacity: 0.7;
}
.menu__content > .menu-item > a {
  line-height: 44px;
  min-width: 60px;
}
.sub-menu {
  padding: 0 0 0 44px;
}
.menu-item {
  line-height: 44px;
  min-height: 44px;
  padding: 0 12px;
  transform: translateX(-100%);
}

.menu-item:hover {
    opacity: 1;
}


.menu-item .menu-item {
  transform: translateX(-150%);
}
.menu-item:hover .menu-item {
  transform: translateX(-150%);
  display: block;
}

@media (min-width: 640px) {
  .search {
    margin: 0.5rem;
    margin-right: 1.5rem;
  }
  
  .search_box {
    margin-left: 41.5%;
    margin-right: 1rem;
    width: 60%;
  }
  
  .search_panel {
    right: 1rem;
    width: 29%;
  }
}

@media (min-width: 991px) {
  .menu__toggle:checked ~ .menu__toggle-label {
    visibility: hidden;
    opacity: 0;
  }

  .menu__content {
    visibility: hidden;
    opacity: 0;
  }

  .side_menu svg {
    cursor: pointer;
    height: 44px;
    width: 44px;
    visibility: hidden;
    opacity: 0;
  }

  .search {
    margin: 0.5rem;
    margin-right: 1.5rem;
  }
  
  .search_box {
    margin-left: 41.5%;
    margin-right: 1rem;
    width: 50%;
  }
  
  .search_panel {
    right: 6%;
    width: 24.4%;
    padding: 0rem 0.75rem;
  }

  .search_panel p {
    margin: 1rem 0rem;
  }
  
}
