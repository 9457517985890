.medical_main_body {
    margin: 2% 10%;
    margin-bottom: 5%;
    padding: 2% 10%;
}

.medical_main .main {
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 15rem;
}

.medical_main .overlay {
    background: rgba(0, 0, 0, 0.5);
    height: 15rem;
}

.medical_main .heading {
    color: #fff;
    position: absolute;
    bottom: 2rem;
    left: 5rem;
    z-index: 1 !important;
}

.medical_head {
    display: table;
    margin: auto;
  }
  
  .medical_head:after {
    content:''; 
    display:block; 
    width:50%; 
    height:0.3rem; 
    background: rgb(230, 44, 30); 
    margin:0 auto; 
    margin-top:0.5rem; 
    border-radius:0.2rem;
  }

.medical_main_body .title {
    text-align: center;
}

.medical_main_body .description {
    margin-top: 3%;
    padding-top: 3%;
    font-size: larger;
    text-align: justify;
    text-justify: inter-word;
}

.medical_main_body .description > * {
    margin-bottom: 1%;
    padding-bottom: 1%;
}

.medical_main_body .sub {
    margin-top: 1%;
    padding-top: 1%;
}

.medical_main_body .sub_title h3 {
    margin: 1% 0%;
    padding: 1% 0%;
}

.medical_main_body .sub_description {
    font-size: large;
    text-align: justify;
    text-justify: inter-word;
}

.medical_main_body .sub_description li {
    padding-bottom: 1%;
}

@media (max-width: 640px) {
    .medical_main_body {
        margin-bottom: 4rem;
        padding: 0.5rem;
    }

    .medical_main .main {
        width: 100%;
        height: 10rem !important;
    }

    .medical_main .overlay {
        height: 10rem !important;
    }

    .medical_main .heading {
        position: absolute;
        bottom: 1rem !important;
        left: 1rem !important;
        font-size: 2rem;
    }
    
    .medical_main_body .title h1 {
        font-size: 2rem;
    }

    .medical_main_body .sub_title h3 {
        margin: 1rem 0rem 0.5rem;
        padding: 1rem 0rem 0.5rem;
        font-size: 1.5rem;
    }

    .medical_main_body ul {
        margin-bottom: 0.25rem;
        padding-left: 1.5rem;
    }

}