.testing_body,
.medical_body {
    margin: 2% 10%;
    margin-bottom: 5%;
    padding: 2% 10%;
}

.testing .main,
.medical .main {
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 15rem;
}

.testing .overlay,
.medical .overlay {
    background: rgba(0, 0, 0, 0.5);
    height: 15rem;
}

.testing .heading,
.medical .heading {
    color: #fff;
    position: absolute;
    bottom: 2rem;
    left: 5rem;
    z-index: 1 !important;
}

.testing_body .title,
.medical_body .title {
    text-align: center;
}

.testing_body .image img,
.medical_body .image img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto !important;
    height: 22rem;
}

.testing_body .description,
.medical_body .description {
    margin-top: 3%;
    padding-top: 3%;
    font-size: larger;
    text-align: justify;
    text-justify: inter-word;
}

.testing_body .description,
.medical_body .description > * {
    margin-bottom: 1%;
    padding-bottom: 1%;
}

.testing_body .parameters {
    margin-top: 3%;
    padding-top: 3%; 
}

.testing_body .parameters_title {
    font-size: 1.25rem;
}

.testing_body .parameters_description {
    font-size: medium;
    padding-left: 1rem;
}

.testing_body .sub_title,
.medical_body .sub_title {
    font-size: x-large;
}

.testing_body .sub_description,
.medical_body .sub_description {
    font-size: medium;
    margin-bottom: 1%;
    padding-bottom: 1%;
}

.testing_body .sub_parameters {
    margin-top: 1%;
    padding-top: 1%;

}

.testing_body .parameters_description_1 {
    margin: 1% 0%;
    padding: 1% 0%;
    padding-left: 1rem;
    font-size: medium;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.testing_body .parameters_description_2 {
    margin-bottom: -1em;
    margin-top: 0em;
    font-size: medium;
}

.medical_body .table {
    margin: 5% auto;
    padding: 5% auto;
}

.medical_body .table1 {
    margin: 5% auto;
    padding: 5% auto;
}

.medical_body .tablerounededCorner {
    border: 1px solid #000;
    border-radius: 1.2em;
}

.medical_body .roundedTable {
    border-collapse: collapse;
    border-radius: 1.2em;
    overflow: hidden;
    width: 100%;
    margin: 0;
}

.medical_body .sub {
    margin-top: 3%;
    padding-top: 3%;
}

.medical_body .sub_title h3 {
    margin: 1% 0%;
    padding: 1% 0%;
}

.testing_body .sub_description,
.medical_body .sub_description {
    font-size: large;
    text-align: justify;
    text-justify: inter-word;
}

.medical_body .sub_description li {
    padding-bottom: 1%;
}

.medical_body .sub_image {
    padding-top: 2%;
}


.testing_body .image,
.medical_body .image {
    margin-bottom: 4%;
    padding: 2%;
}

@media (max-width: 991px) {
    .testing_body .title h1,
    .medical_body .title h1 {
        font-size: 2rem;
    }

}

@media (max-width: 640px) {
    .testing_body,
    .medical_body {
        margin-bottom: 4rem;
        padding: 0.5rem;
    }

    .testing .main,
    .medical .main {
        width: 100%;
        height: 10rem !important;
    }

    .testing .overlay,
    .medical .overlay {
        height: 10rem !important;
    }

    .testing .heading,
    .medical .heading {
        position: absolute;
        bottom: 1rem !important;
        left: 1rem !important;
        font-size: 2rem;
    }

    .testing_body .image img,
    .medical_body .image img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: auto !important;
        height: 15rem;
    }

    .testing_body .title h1,
    .medical_body .title h1 {
        font-size: 2rem;
        margin: 1rem 0rem;
    }

    .medical_body .table {
        margin: 2rem auto;
    }

    .medical_body .sub_title h3 {
        margin: 1rem 0rem 0.5rem;
        padding: 1rem 0rem 0.5rem;
        font-size: 1.5rem;
    }

    .medical_body ul {
        margin-bottom: 0.25rem;
        padding-left: 1.5rem;
    }

    .medical_body .sub_image .image {
        height: 4rem;
        margin: 1rem auto;
    }

    .medical_body .table1 {
        font-size: 0.8rem;
    }

    .medical_body .table1 th {
        padding: 0.5rem;
    }

    .medical_body .table1 td {
        padding: 0.5rem;
    }
}