.certificate .main {
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 15rem;
}

.certificate .overlay {
  background: rgba(0, 0, 0, 0.5);
  height: 15rem;
}

.certificate .headings {
  color: #fff;
  position: absolute;
  bottom: 2rem;
  left: 5rem;
  z-index: 1 !important;
}

.certificate_head {
  display: table;
  margin: 2% auto;
  padding-top: 2%;
}

.certificate_head:after {
  content:''; 
  display:block; 
  width:50%; 
  height:0.3rem; 
  background: rgb(230, 44, 30);
  margin:0 auto; 
  margin-top:0.5rem; 
  border-radius:0.2rem;
}

.certificate_body {
  margin: 2%;
  margin-bottom: 5%;
  padding: 2%;
}

.certificate_body .certificates {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: center;
  padding: 0rem 2rem;
}

.certificate_body .certificates a {
  width: 20rem;
}

.certificate_body .certificates img {
  margin: 2rem 2rem 0.5rem;
  border-radius: 0.2rem;
  width: 15rem;
  height: 20rem;
}

.certificate_body .certificates img:hover {
  cursor: pointer;
  opacity:0.7 !important;
}

.certificate_body .certificates p {
  text-align: center;
  font-size: large;
}

.lg-icon {
  color: #fff !important;
}

@media (max-width: 640px) {

  .certificate_head {
    font-size: 2rem;
  }

  .certificate .main {
    width: 100%;
    height: 10rem !important;
  }

  .certificate .overlay {
    height: 10rem !important;
  }

  .certificate .headings {
    position: absolute;
    bottom: 1rem !important;
    left: 1rem !important;
    font-size: 2rem;
  }

  .certificate_body {
    margin-bottom: 4rem;
    padding: 0.5rem;
  }

  .certificate_body .certificates {
    gap: 0rem;
  }

  .certificate_body .certificates img {
    margin: 1rem;
  }

}