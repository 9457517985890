.sliding_images .carousel img {
    height: 35rem;
    margin: 0rem auto;
}

.sliding_images .carousel-inner {
    position: relative;
}

.sliding_images .overlays {
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    background-color: #000;
}


@media (max-width: 1007px) {
    .sliding_images .carousel img {
        height: 30rem;
        width: 100%;
        margin: 0rem auto;
    }
}

@media (max-width: 640px) {
    .sliding_images .carousel img {
        height: 20rem;
        width: 100%;
        margin: 0rem auto;
    }
}