.contact .main {
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 15rem;
}
  
.contact .overlay {
  background: rgba(0, 0, 0, 0.5);
  height: 15rem;
}
  
.contact .headings {
  color: #fff;
  position: absolute;
  bottom: 2rem;
  left: 5rem;
  z-index: 1 !important;
}

.contact_head {
    display: table;
    margin: 2% auto;
    padding-top: 2%;
}

.contact_head:after {
    content:''; 
    display:block; 
    width:50%; 
    height:0.3rem; 
    background: rgb(230, 44, 30); 
    margin:0 auto; 
    margin-top:0.5rem; 
    border-radius:0.2rem;
}

.contact_body {
    margin: 5%;
    padding: 2%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}
  
.contact_body > * {
    flex: 1 1 30%;
}

.contact_body .card {
    width: 100%;
}

.contact_body .card-body > * {
    margin: auto;
    padding: 0% 4%;
}

.contact_body .card-title > * {
    margin: 4% 0% 8% 0%;
}

.contact_form .rows {
    display: flex;
    gap: 2rem;
}

.contact_form .rows > * {
    flex: 1 1 50%;
}

.contact_form .button {
    width: 50%;
    margin: auto;
    margin-top: 4%;
}

.contact_form span {
    color: #bf1650;
}

.contact_details {
    padding: 2%;
}

.contact_details > * {
    margin-bottom: 2rem;
}

.contact_details h2 {
    margin-bottom: 3rem;
    text-align: center;
}

.contact_details .details_title {
    font-size: larger;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.contact_details .details_description {
    font-size: large;
}

.contact_details img {
    width: 100%;
}
  
  /*.form p::before {
    display: inline;
    content: "⚠ ";
  }*/

@media (max-width: 50rem) {
    .contact_body {
        flex-direction: column;
    }
}

@media (max-width: 640px) {
    .contact_head {
        font-size: 2rem;
    }

    .contact .main {
        width: 100%;
        height: 10rem !important;
    }
    
    .contact .overlay {
        height: 10rem !important;
    }
    
    .contact .headings {
        position: absolute;
        bottom: 1rem !important;
        left: 1rem !important;
        font-size: 2rem;
    }

    .contact_body {
        margin-bottom: 4rem;
        padding: 0.5rem;
    }

    .contact_form .rows {
        flex-direction: column;
        gap: 0rem;
    }

    .contact_details h2 {
        font-size: 1.75rem;
        margin-bottom: 2rem;
    }
    
}