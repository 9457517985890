/* main-header start */
[data-target="#mainMenu"] {
  position: relative;
  z-index: 999;
}

.container {
  margin-left: 0% !important;
  margin-right: 0% !important;
  padding-right: 0% !important;
  width: 100% !important;
}

.headname {
  text-align: center;
  margin-left: 5%;
}

.headname:hover {
  color: #fff;
}

.headname h2 {
  font-size: 1.2rem;
  line-height: 0%;
  font-weight: 600;
}

.headname span {
  font-size: 0.7rem;
  line-height: 0%;
}

#mainMenu {
  margin-right: 5%;
}

.navbar-toggler {
  margin-right: 5% !important;
}


#mainMenu li > a {
  font-size: medium;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 500;
  position: relative;
  z-index: 1;
  text-decoration: none;
}

.main-header.fixed-nav #mainMenu li > a {
  color: #fff;
  text-decoration: none;
}

#mainMenu li:not(:last-of-type) {
  margin-right: 30px;
}

#mainMenu li > a::before {
  position: absolute;
  content: "";
  width: calc(100% - 1px);
  height: 1.5px;
  background: #fff;
  bottom: -6px;
  left: 0;

  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
  z-index: -1;

  -webkit-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
}

#mainMenu li > a:hover::before,
#mainMenu li > a.active::before {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.main-header.fixed-nav #mainMenu li > a::before {
  background: #fff;
}

.main-header {
  position: relative;
  padding-top: 40px;
  left: 0;
  z-index: 99;
  width: 100%;
  background-color: rgb(193, 28, 28);
  box-shadow: 0 4px 6px rgba(0,0,0,0.4);
}


.main-header.fixed-nav {
  top: 0;
  background: #fff;
  -webkit-box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
  box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
}

.main-header.fixed-nav .navbar-brand > img:last-of-type {
  display: block;
}

.main-header.fixed-nav .navbar-brand > img:first-of-type {
  display: none;
}
.navbar-brand {
  color: #fff;
}
/* main-header end */


@media (max-width: 1200px) {
  .headname h2 {
    font-size: 0.9rem;
    font-weight: 600;
  }
  
  .headname span {
    font-size: 0.6rem;
  }
  
  #mainMenu li > a {
    font-size: small;
    letter-spacing: 1px;
    font-weight: 500;
  }
}




@media (max-width: 991px) {
  /*header starts*/

  .collapse.in {
    display: block !important;
    padding: 0;
    clear: both;
  }

  .navbar-toggler {
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 25px;
    right: 0;
    border: none;
    border-radius: 0;
    outline: none !important;
  }

  .main-header {
    position: relative;
    padding-top: 25px;
    left: 0;
    z-index: 99;
    width: 100%;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background: rgb(193, 28, 28);
    
  }

  .sub_menu {
  position: absolute;
	z-index: 999;
	background-color:rgba(0, 0, 0, 0.8);
	box-shadow: -2px 2px 70px -25px rgba(0,0,0,0.3); 
	padding: 20px 30px;
	transition: all 0.5s ease;
	margin-top:25px;
  border-radius: 5px;
  visibility: hidden;
	opacity: 0;
}

  #mainMenu {
    position: absolute;
    width: 100%;
    top: 4rem;
    background: rgba(0,0,0,0.9);
  }

  #mainMenu li {
    margin: 0.5rem 0rem !important;
  }

  .main-header .navbar {
    float: none;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .main-header .navbar-nav {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .main-header .navbar-nav li .nav-link {
    text-align: center;
    padding: 20px 15px;
    border-radius: 0px;
  }

  .main-header .navbar-toggler .icon-bar {
    background-color: #fff;
    margin: 0 auto 6px;
    border-radius: 0;
    width: 30px;
    height: 3px;
    position: absolute;
    right: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .main-header .navbar .navbar-toggler .icon-bar:first-child {
    margin-top: 3px;
  }

  .main-header .navbar-toggler .icon-bar-1 {
    width: 10px;
    top: 0px;
  }

  .main-header .navbar-toggler .icon-bar-2 {
    width: 16px;
    top: 12px;
  }

  .main-header .navbar-toggler .icon-bar-3 {
    width: 20px;
    top: 21px;
  }

  .main-header .current .icon-bar {
    margin-bottom: 5px;
    border-radius: 0;
    display: block;
  }

  .main-header .current .icon-bar-1 {
    width: 18px;
  }

  .main-header .current .icon-bar-2 {
    width: 30px;
  }

  .main-header .current .icon-bar-3 {
    width: 10px;
  }

  .main-header .navbar-toggler:hover .icon-bar {
    background-color: #fff;
  }

  .main-header .navbar-toggler:focus .icon-bar {
    background-color: #fff;
  }

}



.navbar-dropdown {
  position: relative;
}

li.navbar-dropdown:hover .dropdown {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}

li.navbar-dropdown .dropdown {
  visibility: hidden;
  opacity: 0;
  width: 100rem;
  position: absolute;
  padding: 20px 0;
  top: 100%;
  transform: translateY(50px);
  background: rgba(0,0,0,0.7);
  box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 99;
  transition: 0.4s all;
}

.navbar-dropdown li {
  padding: 0.3rem;
}

.navbar-dropdown li > a {
  font-size: 0.7rem !important;
}

.dropdown {
  width: 50%;
}

.dropdown > * {
  flex: 1 1 10% !important;
}

@media (min-width: 991px) {

  .sub_menu {
    position: absolute;
    z-index: 999;
    background-color:rgba(0, 0, 0, 0.8);
    box-shadow: -2px 2px 70px -25px rgba(0,0,0,0.3); 
    padding: 20px 30px;
    transition: all 0.5s ease;
    margin-top:25px;
    border-radius: 5px;
    visibility: hidden;
    opacity: 0;
  }

  .menu_children:hover .sub_menu{
    margin-top: 0;
    visibility: visible;
    opacity: 1;
  }

  .sub_menu > li > a {
    display: inline-block;
    padding: 20px 0 0 0;
    font-size: 15px;
    color: #555555;
    transition: color 0.3s ease;
    text-decoration: none;
    text-transform: capitalize;
  }

  .sub_menu.mega_menu {
    right: 10%;
    transform: translateX(10%);
  }
  .sub_menu.mega_menu1 {
    right: 10%;
    transform: translateX(10%);
  }
  .sub_menu.mega_menu.single_column {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }

  .sub_menu .list_item > li > a {
    display: inline-block;
    padding: 20px 0 0 0;
    font-size: 1rem;
    color: #555555;
    transition: color 0.3s ease;
    text-decoration: none;
    text-transform: capitalize;
  }

  .sub_menu.single_column > .list-item{
    flex: 1 1 25% !important;
    padding: 0 15px !important;
  }

}