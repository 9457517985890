body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/

  font-family:  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;*/

    font-family: sans-serif;
}


/* main-header start */
[data-target="#mainMenu"] {
  position: relative;
  z-index: 999;
}

.container {
  margin-left: 0% !important;
  margin-right: 0% !important;
  padding-right: 0% !important;
  width: 100% !important;
}

.headname {
  text-align: center;
  margin-left: 5%;
}

.headname:hover {
  color: #fff;
}

.headname h2 {
  font-size: 1.2rem;
  line-height: 0%;
  font-weight: 600;
}

.headname span {
  font-size: 0.7rem;
  line-height: 0%;
}

#mainMenu {
  margin-right: 5%;
}

.navbar-toggler {
  margin-right: 5% !important;
}


#mainMenu li > a {
  font-size: medium;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 500;
  position: relative;
  z-index: 1;
  text-decoration: none;
}

.main-header.fixed-nav #mainMenu li > a {
  color: #fff;
  text-decoration: none;
}

#mainMenu li:not(:last-of-type) {
  margin-right: 30px;
}

#mainMenu li > a::before {
  position: absolute;
  content: "";
  width: calc(100% - 1px);
  height: 1.5px;
  background: #fff;
  bottom: -6px;
  left: 0;

  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  transform-origin: right center;
  z-index: -1;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

#mainMenu li > a:hover::before,
#mainMenu li > a.active::before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.main-header.fixed-nav #mainMenu li > a::before {
  background: #fff;
}

.main-header {
  position: relative;
  padding-top: 40px;
  left: 0;
  z-index: 99;
  width: 100%;
  background-color: rgb(193, 28, 28);
  box-shadow: 0 4px 6px rgba(0,0,0,0.4);
}


.main-header.fixed-nav {
  top: 0;
  background: #fff;
  box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
}

.main-header.fixed-nav .navbar-brand > img:last-of-type {
  display: block;
}

.main-header.fixed-nav .navbar-brand > img:first-of-type {
  display: none;
}
.navbar-brand {
  color: #fff;
}
/* main-header end */


@media (max-width: 1200px) {
  .headname h2 {
    font-size: 0.9rem;
    font-weight: 600;
  }
  
  .headname span {
    font-size: 0.6rem;
  }
  
  #mainMenu li > a {
    font-size: small;
    letter-spacing: 1px;
    font-weight: 500;
  }
}




@media (max-width: 991px) {
  /*header starts*/

  .collapse.in {
    display: block !important;
    padding: 0;
    clear: both;
  }

  .navbar-toggler {
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 25px;
    right: 0;
    border: none;
    border-radius: 0;
    outline: none !important;
  }

  .main-header {
    position: relative;
    padding-top: 25px;
    left: 0;
    z-index: 99;
    width: 100%;
    transition: all 0.4s ease;
    background: rgb(193, 28, 28);
    
  }

  .sub_menu {
  position: absolute;
	z-index: 999;
	background-color:rgba(0, 0, 0, 0.8);
	box-shadow: -2px 2px 70px -25px rgba(0,0,0,0.3); 
	padding: 20px 30px;
	transition: all 0.5s ease;
	margin-top:25px;
  border-radius: 5px;
  visibility: hidden;
	opacity: 0;
}

  #mainMenu {
    position: absolute;
    width: 100%;
    top: 4rem;
    background: rgba(0,0,0,0.9);
  }

  #mainMenu li {
    margin: 0.5rem 0rem !important;
  }

  .main-header .navbar {
    float: none;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .main-header .navbar-nav {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .main-header .navbar-nav li .nav-link {
    text-align: center;
    padding: 20px 15px;
    border-radius: 0px;
  }

  .main-header .navbar-toggler .icon-bar {
    background-color: #fff;
    margin: 0 auto 6px;
    border-radius: 0;
    width: 30px;
    height: 3px;
    position: absolute;
    right: 0;
    transition: all 0.2s ease;
  }

  .main-header .navbar .navbar-toggler .icon-bar:first-child {
    margin-top: 3px;
  }

  .main-header .navbar-toggler .icon-bar-1 {
    width: 10px;
    top: 0px;
  }

  .main-header .navbar-toggler .icon-bar-2 {
    width: 16px;
    top: 12px;
  }

  .main-header .navbar-toggler .icon-bar-3 {
    width: 20px;
    top: 21px;
  }

  .main-header .current .icon-bar {
    margin-bottom: 5px;
    border-radius: 0;
    display: block;
  }

  .main-header .current .icon-bar-1 {
    width: 18px;
  }

  .main-header .current .icon-bar-2 {
    width: 30px;
  }

  .main-header .current .icon-bar-3 {
    width: 10px;
  }

  .main-header .navbar-toggler:hover .icon-bar {
    background-color: #fff;
  }

  .main-header .navbar-toggler:focus .icon-bar {
    background-color: #fff;
  }

}



.navbar-dropdown {
  position: relative;
}

li.navbar-dropdown:hover .dropdown {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

li.navbar-dropdown .dropdown {
  visibility: hidden;
  opacity: 0;
  width: 100rem;
  position: absolute;
  padding: 20px 0;
  top: 100%;
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
  background: rgba(0,0,0,0.7);
  box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 99;
  transition: 0.4s all;
}

.navbar-dropdown li {
  padding: 0.3rem;
}

.navbar-dropdown li > a {
  font-size: 0.7rem !important;
}

.dropdown {
  width: 50%;
}

.dropdown > * {
  flex: 1 1 10% !important;
}

@media (min-width: 991px) {

  .sub_menu {
    position: absolute;
    z-index: 999;
    background-color:rgba(0, 0, 0, 0.8);
    box-shadow: -2px 2px 70px -25px rgba(0,0,0,0.3); 
    padding: 20px 30px;
    transition: all 0.5s ease;
    margin-top:25px;
    border-radius: 5px;
    visibility: hidden;
    opacity: 0;
  }

  .menu_children:hover .sub_menu{
    margin-top: 0;
    visibility: visible;
    opacity: 1;
  }

  .sub_menu > li > a {
    display: inline-block;
    padding: 20px 0 0 0;
    font-size: 15px;
    color: #555555;
    transition: color 0.3s ease;
    text-decoration: none;
    text-transform: capitalize;
  }

  .sub_menu.mega_menu {
    right: 10%;
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
  }
  .sub_menu.mega_menu1 {
    right: 10%;
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
  }
  .sub_menu.mega_menu.single_column {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 2rem;
    gap: 2rem;
  }

  .sub_menu .list_item > li > a {
    display: inline-block;
    padding: 20px 0 0 0;
    font-size: 1rem;
    color: #555555;
    transition: color 0.3s ease;
    text-decoration: none;
    text-transform: capitalize;
  }

  .sub_menu.single_column > .list-item{
    flex: 1 1 25% !important;
    padding: 0 15px !important;
  }

}
.footer a {
    text-decoration:none;
    outline:none !important;
    color:#fff;
}
    
.border-shape {
    background: rgb(230, 44, 30) none repeat scroll 0 0;
    color: #fff;
    display: block;
    height: 3px;
    left: 0;
    margin: 20px auto;
    position: relative;
    right: 0;
    text-align: center;
    top: 0;
    width: 80px;
}
    
.border-shape::before {
    background: rgb(230, 44, 30) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 1px;
    left: 80px;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    top: 1px;
    width: 100px;
}
    
.border-shape::after {
    background: rgb(230, 44, 30) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 1px;
    margin: 0 auto;
    position: absolute;
    right: 80px;
    text-align: center;
    top: 1px;
    width: 100px;
}

footer {
    width:100%;
    background-color:#121619;
    color:#fff;
}
    
.top_header {
    padding:2rem;
    display:flex;
    align-items:center;
    justify-content:center;
    position:relative;
}
    
.top_header section {
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
}
    
.top_header span {
    padding:0 1rem;
}
    
.top_header .fa {
    color:rgb(230, 44, 30);
    font-size:35px;
}
    
footer .border-shape {
    width: 40%;
}
    
footer .border-shape::before {
    width: 100%;
    left:120px;
}
    
footer .border-shape::after {
    width: 100%;
    right:120px;
}
    
footer .bottom_content section {
    padding:1.5rem 2rem;
    display:flex;
    align-items:center;
    justify-content:Center;
}
    
.bottom_content a {
    margin:0 20px;
    color:rgba(255,255,255,0.7);
    transition:0.5s;
}
    
.bottom_content a:hover {
    color:rgba(255,255,255,1);
}
    
    
.copyright {
    padding:0.8em 0;
    background-color:#1e1e1e;
    text-align:center;
    color:rgba(255,255,255,0.7);
    font-size:12px;
}
    
    
    
@media (max-width:820px){
    .top_header {
        padding:1rem;
        display:block;
    }
    
    .top_header section {
        margin:40px 0;
        align-items:left;
        justify-content:left;
    }

    footer .bottom_content section {
        padding:1rem;
        display:block;
    }

    footer .bottom_content section a {
        padding:1rem;
        font-size:12px;
        margin:0 5px;
        display:inline-block;
    }

}
.sliding_images .carousel img {
    height: 35rem;
    margin: 0rem auto;
}

.sliding_images .carousel-inner {
    position: relative;
}

.sliding_images .overlays {
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    background-color: #000;
}


@media (max-width: 1007px) {
    .sliding_images .carousel img {
        height: 30rem;
        width: 100%;
        margin: 0rem auto;
    }
}

@media (max-width: 640px) {
    .sliding_images .carousel img {
        height: 20rem;
        width: 100%;
        margin: 0rem auto;
    }
}
.home_card_body {
    padding: 5% auto;
}

.home_cards {
    padding: 4% 0%;
}

.home_cards_head {
    text-align: center;
}

.home_card {
    margin: 4%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
}

.home_card .overlay {
    background-color: #fff;
}

.home_card .imgs {
    height: 15rem;
    background-color: #fff;
}

.home_card .imgp {
    display: block;
    margin: 0% auto;
    height: 15rem;
    width: auto;
    background-color: #fff;
}

.home_card .card-body {
    padding-bottom: 0rem;
}

.home_card .card {
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 2px;
    width: 300px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .15), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    margin: 0rem;
    border-radius: 5px;
}
.card .card-title h1 {
    margin: 0;
    padding: 0.75rem;
    padding-bottom: 0rem;
    font-size: 1.5rem;
    text-align: center;
}

.card .card-image {
    height: 150px;
    background-color: #b5add6;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.home_card .card_btn:hover {
    text-decoration: none;
    background-color: rgb(192, 208, 208);
}

.home_cards .main_button {
    position: relative;
    padding: 0.5rem 1.5rem;
    font-size: medium;
    font-weight: 400;
    border-radius: 20px;
    margin:0 auto;
    display:block;
}

.home_card_body .fixed-bg {
    margin: 4% 0%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100%; 
    width: 100%;
    color: white;
    text-align: center;
    display: table;
}

.home_card_body .customers {
    position: relative;
    display: flex;
    padding: 2rem;
    justify-content: center;
    flex-wrap: wrap;
    background: rgba(0,0,0,0.70);
}

.home_card_body .customers > * {
    text-align: center;
    color: #fff;
    flex: 1 1 20%;
    margin: 1rem;
    padding: 1rem;
}


  @media (max-width: 1007px) {

    .home_card .card {
        width: 250px;
    }

    .customers .numbers h1 {
        font-size: 2.5rem;
    }

    .customers .numbers h3 {
        font-size: 1.5rem;
    }

}
  



  @media (max-width: 640px) {

    .customers .numbers h1 {
        color: #fff;
    }

    .home_cards_head {
        font-size: 2rem !important;
    }

    .home_cards {
        padding-bottom: 2rem;
    }

    .home_card {
        margin-bottom: 1.5rem;
    }
    
    .card .card-title {
        margin: 0;
        padding: 0.25rem;
        padding-bottom: 0rem;
        font-size: 1.5rem;
        text-align: center;
    }

    .home_card .card-body {
        padding: 0rem !important;
        padding-top: 0.75rem !important;
    }

    .card .card-img-top {
        height: 10rem !important;
    }

    .home_card .card-footer {
        padding: 0.5rem 1rem !important;
    }

    .home_card_body .customers {
        padding: 1.5rem;
    }
    
    .home_card_body .customers > * {
        margin: 0.5rem;
        padding: 0.5rem;
    }
}

  
.about_body {
  margin: 5%;
  display: flex;
  flex-wrap: wrap;
}

.about_body > * {
  flex: 1 1 50%;
}

.about_body .about_description {
  padding: 2rem 2rem 2rem 0rem;
}

.about_body .about_imga img {
  display: block;
  height: 20rem;
  width: 70%;
  margin: 7rem auto;
}

.about_body .about_imgw img {
  display: block;
  height: 15rem;
  width: 60%;
  margin: 5rem auto;
}

.about_body .about_head {
  display: table;
}

.about_body .about_description1 {
  padding: 2rem 2rem 2rem 0rem;
  font-size: 1.2rem;
}

.about_body .about_head:after {
  content: "";
  display: block;
  width: 50%;
  height: 0.3rem;
  background: rgb(230, 44, 30);
  margin: 0 auto;
  margin-top: 0.5rem;
  border-radius: 0.2rem;
}

.values_body .values h2 {
  font-size: large;
  text-align: center;
}

.values_body .values {
  margin: 5%;
  padding: 2%;
  display: flex;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  flex-wrap: wrap;
}

.values_body .values > * {
  flex: 1 1 20%;
}

.values_body .value {
  display: flex;
  flex-direction: column;
}

.values_body .values_head {
  padding-top: 5%;
  text-align: center;
}

.values_body .values img {
  height: 70%;
  width: 30%;
  align-self: center;
  margin-bottom: 5%;
}

.compliance_body,
.team_body {
  padding: 4% 0%;
}

.compliance_body .compliance_head,
.team_body  .team_head {
  text-align: center;
}

.compliance_body p,
.team_body p {
  text-align: center;
}

.compliance_body .compliance_description,
.team_body  .team_description {
  margin: 2% 15%;
  padding: 2% 3%;
  font-size: larger;
}


@media (max-width: 1007px) {

  .about_body .about_head,
  .about_body .about_head1,
  .values_body .values_head,
  .compliance_body .compliance_head,
  .team_body  .team_head {
    font-size: 2rem;
    margin: 0rem auto;
    text-align: center;
  }

  .about_body .about_imga img {
    display: block;
    height: 20rem;
    width: 70%;
    margin: 5rem auto;
  }

  .about_body .about_imgw img {
    display: block;
    height: 10rem;
    width: 60%;
    margin: 4rem auto;
  }

  .about_body .about_description,
  .about_body .about_description1 {
    padding: 1rem 0.5rem 1rem 0rem;
    text-align: justify;
    text-justify: inter-word;
  }

  p {
    font-size: smaller;
  }

}


@media (max-width: 640px) {

  .about_body {
    flex-direction: column;
  }

  .about_body:nth-child(2) {
    flex-direction: column-reverse;
  }

  .about_body .about_imga img,
  .about_body .about_imgw img {
    display: block;
    height: 20rem;
    width: 70%;
    margin: 2rem auto;
  }

  .values_body .value h2 {
    font-size: small;
  }

  .compliance_body .compliance_description p,
  .team_body .team_description p {
    padding: 1rem 0rem 1rem 0rem;
    text-align: justify;
    text-justify: inter-word;
  }

  .about_body .about_description1 {
    padding: 1rem 0.5rem 1rem 0rem;
    text-align: justify;
    text-justify: inter-word;
  }

  .compliance_body,
  .team_body {
    padding: 2.5rem 0rem;
  }

  .compliance_body .compliance_head,
  .team_body .team_head {
    font-size: 2rem;
  }

  .compliance_body .compliance_description p,
  .team_body .team_description p {
    padding: 0rem;
  }
  
}

.mission_vision_body {
  background-color: rgb(248, 232, 222);
}

.mission_vision_body .mission,
.mission_vision_body .vision {
  margin: 2% 5% 0% 5%;
  padding-top: 4%;
  display: flex;
  flex-wrap: wrap;
}

.mission_vision_body .mission > *,
.mission_vision_body .vision > * {
  flex: 1 1 50%;
}

.mission .mission_img img {
  display: block;
  height: 15rem;
  width: 60%;
  margin: 5rem auto;
}

.vision .vision_img img {
  display: block;
  height: 10rem;
  width: 50%;
  margin: 5rem auto;
}

.mission .mission_description,
.vision .vision_description {
  padding: 2rem 2rem 2rem 0rem;
  font-size: 1.2rem;
}


@media (max-width: 1007px) {

  .mission .mission_head,
  .vision .vision_head {
    font-size: 2rem; 
    margin: 0rem auto;
    text-align: center;
  }

  .mission .mission_description,
  .vision .vision_description {
    padding: 1rem 0.5rem 1rem 0rem;
    text-align: justify;
    text-justify: inter-word;
    font-size: 1.2rem;
  }

  .mission .mission_img img {
    display: block;
    height: 15rem;
    width: 70%;
    margin: 4rem auto;
  }

  .vision .vision_img img {
    display: block;
    height: 10rem;
    width: 60%;
    margin: 4rem auto;
  }

}


@media (max-width: 640px) {

  .mission_vision_body .mission,
  .mission_vision_body .vision {
    flex-direction: column;
  }

  .mission_vision_body .mission:nth-child(2),
  .mission_vision_body .vision:nth-child(2) {
    flex-direction: column-reverse;
  }

  .mission .mission_img img {
    display: block;
    height: 20rem;
    width: 70%;
    margin: 2rem auto;
  }

  .vision .vision_img img {
    display: block;
    height: 20rem;
    width: 70%;
    margin: 2rem auto;
  }
  
}
.side_menu svg {
  cursor: pointer;
  height: 44px;
  width: 44px;
}
.side_menu svg path {
  fill: #fff;
}
.side_menu ol {
  list-style-type: none;
}
.side_menu a[href] {
  color: #fff;
  position: relative;
  text-decoration: none;
}
.side_menu a[href]:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.side_menu a[href]:after {
  content: '';
  position: absolute;
  top: 100%;
  height: 4px;
  background: #fff;
  left: 0;
  right: 0;
  transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}
.side_menu {
  margin: 0%;
  display: flex;
  background-color: rgb(33, 33, 34);
}

.side_menu > * {
  flex: 1 1 30%;
}

.search {
  margin: 0.5rem;
  margin-right: 1rem;
}

.search_panel {
  position: absolute;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1;
  right: 1rem;
  width: 47%;
  box-shadow: 0 4px 6px 4px rgba(0,0,0,0.2);
  margin: 0rem;
  padding: 0rem 0.75rem;
}

.search_panel p {
  margin: 0.75rem;
}

.menu {
  position: relative;
  width: 250px;
}
.menu__content {
  color: #fff;
  margin: 0;
  padding: 0 0 25px 0;
  position: absolute;
  right: 100%;
  top: 0;
  margin-top: 4rem;
  width: 20rem;
  z-index: 2;
}
.menu__toggle-label {
  height: 44px;
  left: 0;
  position: absolute;
  width: 44px;
}
.menu__toggle-label svg {
  left: 2rem;
  position: absolute;
  top: 7%;
  transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
  z-index: 2;
}
.menu__toggle-label svg:nth-of-type(2) {
  left: 400px;
  -webkit-transform: scale(0);
          transform: scale(0);
}
.menu__toggle {
  opacity: 0;
  position: fixed;
}
.menu__toggle:checked ~ .menu__toggle-label {
  background: rgba(0,0,0,0.9);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  margin-top: 0rem;
  transition: background 0.15s;
  width: 100vw;
  z-index: 2;
}
.menu__toggle:checked ~ .menu__toggle-label svg:nth-of-type(1) {
  -webkit-transform: scale(0);
          transform: scale(0);
}
.menu__toggle:checked ~ .menu__toggle-label svg:nth-of-type(2) {
  top: 6rem;
  left: 300px;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
  transition-delay: 0.925s;
}
.menu__toggle:checked ~ .menu__content {
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
}
.menu__toggle:checked ~ .menu__content .menu-item {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: border-color 0.15s, -webkit-transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275), border-color 0.15s;
  transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275), border-color 0.15s, -webkit-transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.menu__toggle:checked ~ .menu__content .menu-item {
  border-color: #e26a6a;
}
.menu__toggle:checked ~ .menu__content .menu-item a[href]:after {
  background: #e26a6a;
}

.menu__content > .menu-item {
  border-left: 8px solid transparent;
  opacity: 0.7;
}
.menu__content > .menu-item > a {
  line-height: 44px;
  min-width: 60px;
}
.sub-menu {
  padding: 0 0 0 44px;
}
.menu-item {
  line-height: 44px;
  min-height: 44px;
  padding: 0 12px;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.menu-item:hover {
    opacity: 1;
}


.menu-item .menu-item {
  -webkit-transform: translateX(-150%);
          transform: translateX(-150%);
}
.menu-item:hover .menu-item {
  -webkit-transform: translateX(-150%);
          transform: translateX(-150%);
  display: block;
}

@media (min-width: 640px) {
  .search {
    margin: 0.5rem;
    margin-right: 1.5rem;
  }
  
  .search_box {
    margin-left: 41.5%;
    margin-right: 1rem;
    width: 60%;
  }
  
  .search_panel {
    right: 1rem;
    width: 29%;
  }
}

@media (min-width: 991px) {
  .menu__toggle:checked ~ .menu__toggle-label {
    visibility: hidden;
    opacity: 0;
  }

  .menu__content {
    visibility: hidden;
    opacity: 0;
  }

  .side_menu svg {
    cursor: pointer;
    height: 44px;
    width: 44px;
    visibility: hidden;
    opacity: 0;
  }

  .search {
    margin: 0.5rem;
    margin-right: 1.5rem;
  }
  
  .search_box {
    margin-left: 41.5%;
    margin-right: 1rem;
    width: 50%;
  }
  
  .search_panel {
    right: 6%;
    width: 24.4%;
    padding: 0rem 0.75rem;
  }

  .search_panel p {
    margin: 1rem 0rem;
  }
  
}

.testing_body,
.medical_body {
    margin: 2% 10%;
    margin-bottom: 5%;
    padding: 2% 10%;
}

.testing .main,
.medical .main {
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 15rem;
}

.testing .overlay,
.medical .overlay {
    background: rgba(0, 0, 0, 0.5);
    height: 15rem;
}

.testing .heading,
.medical .heading {
    color: #fff;
    position: absolute;
    bottom: 2rem;
    left: 5rem;
    z-index: 1 !important;
}

.testing_body .title,
.medical_body .title {
    text-align: center;
}

.testing_body .image img,
.medical_body .image img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto !important;
    height: 22rem;
}

.testing_body .description,
.medical_body .description {
    margin-top: 3%;
    padding-top: 3%;
    font-size: larger;
    text-align: justify;
    text-justify: inter-word;
}

.testing_body .description,
.medical_body .description > * {
    margin-bottom: 1%;
    padding-bottom: 1%;
}

.testing_body .parameters {
    margin-top: 3%;
    padding-top: 3%; 
}

.testing_body .parameters_title {
    font-size: 1.25rem;
}

.testing_body .parameters_description {
    font-size: medium;
    padding-left: 1rem;
}

.testing_body .sub_title,
.medical_body .sub_title {
    font-size: x-large;
}

.testing_body .sub_description,
.medical_body .sub_description {
    font-size: medium;
    margin-bottom: 1%;
    padding-bottom: 1%;
}

.testing_body .sub_parameters {
    margin-top: 1%;
    padding-top: 1%;

}

.testing_body .parameters_description_1 {
    margin: 1% 0%;
    padding: 1% 0%;
    padding-left: 1rem;
    font-size: medium;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 2rem;
    gap: 2rem;
}

.testing_body .parameters_description_2 {
    margin-bottom: -1em;
    margin-top: 0em;
    font-size: medium;
}

.medical_body .table {
    margin: 5% auto;
    padding: 5% auto;
}

.medical_body .table1 {
    margin: 5% auto;
    padding: 5% auto;
}

.medical_body .tablerounededCorner {
    border: 1px solid #000;
    border-radius: 1.2em;
}

.medical_body .roundedTable {
    border-collapse: collapse;
    border-radius: 1.2em;
    overflow: hidden;
    width: 100%;
    margin: 0;
}

.medical_body .sub {
    margin-top: 3%;
    padding-top: 3%;
}

.medical_body .sub_title h3 {
    margin: 1% 0%;
    padding: 1% 0%;
}

.testing_body .sub_description,
.medical_body .sub_description {
    font-size: large;
    text-align: justify;
    text-justify: inter-word;
}

.medical_body .sub_description li {
    padding-bottom: 1%;
}

.medical_body .sub_image {
    padding-top: 2%;
}


.testing_body .image,
.medical_body .image {
    margin-bottom: 4%;
    padding: 2%;
}

@media (max-width: 991px) {
    .testing_body .title h1,
    .medical_body .title h1 {
        font-size: 2rem;
    }

}

@media (max-width: 640px) {
    .testing_body,
    .medical_body {
        margin-bottom: 4rem;
        padding: 0.5rem;
    }

    .testing .main,
    .medical .main {
        width: 100%;
        height: 10rem !important;
    }

    .testing .overlay,
    .medical .overlay {
        height: 10rem !important;
    }

    .testing .heading,
    .medical .heading {
        position: absolute;
        bottom: 1rem !important;
        left: 1rem !important;
        font-size: 2rem;
    }

    .testing_body .image img,
    .medical_body .image img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: auto !important;
        height: 15rem;
    }

    .testing_body .title h1,
    .medical_body .title h1 {
        font-size: 2rem;
        margin: 1rem 0rem;
    }

    .medical_body .table {
        margin: 2rem auto;
    }

    .medical_body .sub_title h3 {
        margin: 1rem 0rem 0.5rem;
        padding: 1rem 0rem 0.5rem;
        font-size: 1.5rem;
    }

    .medical_body ul {
        margin-bottom: 0.25rem;
        padding-left: 1.5rem;
    }

    .medical_body .sub_image .image {
        height: 4rem;
        margin: 1rem auto;
    }

    .medical_body .table1 {
        font-size: 0.8rem;
    }

    .medical_body .table1 th {
        padding: 0.5rem;
    }

    .medical_body .table1 td {
        padding: 0.5rem;
    }
}
.testing_main .main {
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 15rem;
}

.testing_main .overlay {
    background: rgba(0, 0, 0, 0.5);
    height: 15rem;
}

.testing_main .heading {
    color: #fff;
    position: absolute;
    bottom: 2rem;
    left: 5rem;
    z-index: 1 !important;
}

.testing_main_body {
    margin: 2% 10%;
    margin-bottom: 5%;
    padding: 2% 10%;
}

.testing_head {
    display: table;
    margin: auto;
}
  
.testing_head:after {
    content:''; 
    display:block; 
    width:50%; 
    height:0.3rem; 
    background: rgb(230, 44, 30); 
    margin:0 auto; 
    margin-top:0.5rem; 
    border-radius:0.2rem;
}

.testing_main_body .title {
    text-align: center;
}

.testing_main_body .description {
    margin-top: 3%;
    padding-top: 3%;
    font-size: larger;
    text-align: justify;
    text-justify: inter-word;
}

.testing_main_body .description > * {
    margin-bottom: 1%;
    padding-bottom: 1%;
}


@media (max-width: 640px) {

    .testing_main .main {
        width: 100%;
        height: 10rem !important;
    }

    .testing_main .overlay {
        height: 10rem !important;
    }

    .testing_main .heading {
        position: absolute;
        bottom: 1rem !important;
        left: 1rem !important;
        font-size: 2rem;
    }

    .testing_main_body {
        margin-bottom: 4rem;
        padding: 0.5rem;
    }
    
    .testing_main_body .title h1 {
        font-size: 2rem;
    }

}
.medical_main_body {
    margin: 2% 10%;
    margin-bottom: 5%;
    padding: 2% 10%;
}

.medical_main .main {
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 15rem;
}

.medical_main .overlay {
    background: rgba(0, 0, 0, 0.5);
    height: 15rem;
}

.medical_main .heading {
    color: #fff;
    position: absolute;
    bottom: 2rem;
    left: 5rem;
    z-index: 1 !important;
}

.medical_head {
    display: table;
    margin: auto;
  }
  
  .medical_head:after {
    content:''; 
    display:block; 
    width:50%; 
    height:0.3rem; 
    background: rgb(230, 44, 30); 
    margin:0 auto; 
    margin-top:0.5rem; 
    border-radius:0.2rem;
  }

.medical_main_body .title {
    text-align: center;
}

.medical_main_body .description {
    margin-top: 3%;
    padding-top: 3%;
    font-size: larger;
    text-align: justify;
    text-justify: inter-word;
}

.medical_main_body .description > * {
    margin-bottom: 1%;
    padding-bottom: 1%;
}

.medical_main_body .sub {
    margin-top: 1%;
    padding-top: 1%;
}

.medical_main_body .sub_title h3 {
    margin: 1% 0%;
    padding: 1% 0%;
}

.medical_main_body .sub_description {
    font-size: large;
    text-align: justify;
    text-justify: inter-word;
}

.medical_main_body .sub_description li {
    padding-bottom: 1%;
}

@media (max-width: 640px) {
    .medical_main_body {
        margin-bottom: 4rem;
        padding: 0.5rem;
    }

    .medical_main .main {
        width: 100%;
        height: 10rem !important;
    }

    .medical_main .overlay {
        height: 10rem !important;
    }

    .medical_main .heading {
        position: absolute;
        bottom: 1rem !important;
        left: 1rem !important;
        font-size: 2rem;
    }
    
    .medical_main_body .title h1 {
        font-size: 2rem;
    }

    .medical_main_body .sub_title h3 {
        margin: 1rem 0rem 0.5rem;
        padding: 1rem 0rem 0.5rem;
        font-size: 1.5rem;
    }

    .medical_main_body ul {
        margin-bottom: 0.25rem;
        padding-left: 1.5rem;
    }

}
.certificate .main {
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 15rem;
}

.certificate .overlay {
  background: rgba(0, 0, 0, 0.5);
  height: 15rem;
}

.certificate .headings {
  color: #fff;
  position: absolute;
  bottom: 2rem;
  left: 5rem;
  z-index: 1 !important;
}

.certificate_head {
  display: table;
  margin: 2% auto;
  padding-top: 2%;
}

.certificate_head:after {
  content:''; 
  display:block; 
  width:50%; 
  height:0.3rem; 
  background: rgb(230, 44, 30);
  margin:0 auto; 
  margin-top:0.5rem; 
  border-radius:0.2rem;
}

.certificate_body {
  margin: 2%;
  margin-bottom: 5%;
  padding: 2%;
}

.certificate_body .certificates {
  display: flex;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: center;
  padding: 0rem 2rem;
}

.certificate_body .certificates a {
  width: 20rem;
}

.certificate_body .certificates img {
  margin: 2rem 2rem 0.5rem;
  border-radius: 0.2rem;
  width: 15rem;
  height: 20rem;
}

.certificate_body .certificates img:hover {
  cursor: pointer;
  opacity:0.7 !important;
}

.certificate_body .certificates p {
  text-align: center;
  font-size: large;
}

.lg-icon {
  color: #fff !important;
}

@media (max-width: 640px) {

  .certificate_head {
    font-size: 2rem;
  }

  .certificate .main {
    width: 100%;
    height: 10rem !important;
  }

  .certificate .overlay {
    height: 10rem !important;
  }

  .certificate .headings {
    position: absolute;
    bottom: 1rem !important;
    left: 1rem !important;
    font-size: 2rem;
  }

  .certificate_body {
    margin-bottom: 4rem;
    padding: 0.5rem;
  }

  .certificate_body .certificates {
    grid-gap: 0rem;
    gap: 0rem;
  }

  .certificate_body .certificates img {
    margin: 1rem;
  }

}
.contact .main {
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 15rem;
}
  
.contact .overlay {
  background: rgba(0, 0, 0, 0.5);
  height: 15rem;
}
  
.contact .headings {
  color: #fff;
  position: absolute;
  bottom: 2rem;
  left: 5rem;
  z-index: 1 !important;
}

.contact_head {
    display: table;
    margin: 2% auto;
    padding-top: 2%;
}

.contact_head:after {
    content:''; 
    display:block; 
    width:50%; 
    height:0.3rem; 
    background: rgb(230, 44, 30); 
    margin:0 auto; 
    margin-top:0.5rem; 
    border-radius:0.2rem;
}

.contact_body {
    margin: 5%;
    padding: 2%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 2rem;
    gap: 2rem;
}
  
.contact_body > * {
    flex: 1 1 30%;
}

.contact_body .card {
    width: 100%;
}

.contact_body .card-body > * {
    margin: auto;
    padding: 0% 4%;
}

.contact_body .card-title > * {
    margin: 4% 0% 8% 0%;
}

.contact_form .rows {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
}

.contact_form .rows > * {
    flex: 1 1 50%;
}

.contact_form .button {
    width: 50%;
    margin: auto;
    margin-top: 4%;
}

.contact_form span {
    color: #bf1650;
}

.contact_details {
    padding: 2%;
}

.contact_details > * {
    margin-bottom: 2rem;
}

.contact_details h2 {
    margin-bottom: 3rem;
    text-align: center;
}

.contact_details .details_title {
    font-size: larger;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.contact_details .details_description {
    font-size: large;
}

.contact_details img {
    width: 100%;
}
  
  /*.form p::before {
    display: inline;
    content: "⚠ ";
  }*/

@media (max-width: 50rem) {
    .contact_body {
        flex-direction: column;
    }
}

@media (max-width: 640px) {
    .contact_head {
        font-size: 2rem;
    }

    .contact .main {
        width: 100%;
        height: 10rem !important;
    }
    
    .contact .overlay {
        height: 10rem !important;
    }
    
    .contact .headings {
        position: absolute;
        bottom: 1rem !important;
        left: 1rem !important;
        font-size: 2rem;
    }

    .contact_body {
        margin-bottom: 4rem;
        padding: 0.5rem;
    }

    .contact_form .rows {
        flex-direction: column;
        grid-gap: 0rem;
        gap: 0rem;
    }

    .contact_details h2 {
        font-size: 1.75rem;
        margin-bottom: 2rem;
    }
    
}
