.footer a {
    text-decoration:none;
    outline:none !important;
    color:#fff;
}
    
.border-shape {
    background: rgb(230, 44, 30) none repeat scroll 0 0;
    color: #fff;
    display: block;
    height: 3px;
    left: 0;
    margin: 20px auto;
    position: relative;
    right: 0;
    text-align: center;
    top: 0;
    width: 80px;
}
    
.border-shape::before {
    background: rgb(230, 44, 30) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 1px;
    left: 80px;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    top: 1px;
    width: 100px;
}
    
.border-shape::after {
    background: rgb(230, 44, 30) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 1px;
    margin: 0 auto;
    position: absolute;
    right: 80px;
    text-align: center;
    top: 1px;
    width: 100px;
}

footer {
    width:100%;
    background-color:#121619;
    color:#fff;
}
    
.top_header {
    padding:2rem;
    display:flex;
    align-items:center;
    justify-content:center;
    position:relative;
}
    
.top_header section {
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
}
    
.top_header span {
    padding:0 1rem;
}
    
.top_header .fa {
    color:rgb(230, 44, 30);
    font-size:35px;
}
    
footer .border-shape {
    width: 40%;
}
    
footer .border-shape::before {
    width: 100%;
    left:120px;
}
    
footer .border-shape::after {
    width: 100%;
    right:120px;
}
    
footer .bottom_content section {
    padding:1.5rem 2rem;
    display:flex;
    align-items:center;
    justify-content:Center;
}
    
.bottom_content a {
    margin:0 20px;
    color:rgba(255,255,255,0.7);
    transition:0.5s;
}
    
.bottom_content a:hover {
    color:rgba(255,255,255,1);
}
    
    
.copyright {
    padding:0.8em 0;
    background-color:#1e1e1e;
    text-align:center;
    color:rgba(255,255,255,0.7);
    font-size:12px;
}
    
    
    
@media (max-width:820px){
    .top_header {
        padding:1rem;
        display:block;
    }
    
    .top_header section {
        margin:40px 0;
        align-items:left;
        justify-content:left;
    }

    footer .bottom_content section {
        padding:1rem;
        display:block;
    }

    footer .bottom_content section a {
        padding:1rem;
        font-size:12px;
        margin:0 5px;
        display:inline-block;
    }

}