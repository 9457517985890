.about_body {
  margin: 5%;
  display: flex;
  flex-wrap: wrap;
}

.about_body > * {
  flex: 1 1 50%;
}

.about_body .about_description {
  padding: 2rem 2rem 2rem 0rem;
}

.about_body .about_imga img {
  display: block;
  height: 20rem;
  width: 70%;
  margin: 7rem auto;
}

.about_body .about_imgw img {
  display: block;
  height: 15rem;
  width: 60%;
  margin: 5rem auto;
}

.about_body .about_head {
  display: table;
}

.about_body .about_description1 {
  padding: 2rem 2rem 2rem 0rem;
  font-size: 1.2rem;
}

.about_body .about_head:after {
  content: "";
  display: block;
  width: 50%;
  height: 0.3rem;
  background: rgb(230, 44, 30);
  margin: 0 auto;
  margin-top: 0.5rem;
  border-radius: 0.2rem;
}

.values_body .values h2 {
  font-size: large;
  text-align: center;
}

.values_body .values {
  margin: 5%;
  padding: 2%;
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.values_body .values > * {
  flex: 1 1 20%;
}

.values_body .value {
  display: flex;
  flex-direction: column;
}

.values_body .values_head {
  padding-top: 5%;
  text-align: center;
}

.values_body .values img {
  height: 70%;
  width: 30%;
  align-self: center;
  margin-bottom: 5%;
}

.compliance_body,
.team_body {
  padding: 4% 0%;
}

.compliance_body .compliance_head,
.team_body  .team_head {
  text-align: center;
}

.compliance_body p,
.team_body p {
  text-align: center;
}

.compliance_body .compliance_description,
.team_body  .team_description {
  margin: 2% 15%;
  padding: 2% 3%;
  font-size: larger;
}


@media (max-width: 1007px) {

  .about_body .about_head,
  .about_body .about_head1,
  .values_body .values_head,
  .compliance_body .compliance_head,
  .team_body  .team_head {
    font-size: 2rem;
    margin: 0rem auto;
    text-align: center;
  }

  .about_body .about_imga img {
    display: block;
    height: 20rem;
    width: 70%;
    margin: 5rem auto;
  }

  .about_body .about_imgw img {
    display: block;
    height: 10rem;
    width: 60%;
    margin: 4rem auto;
  }

  .about_body .about_description,
  .about_body .about_description1 {
    padding: 1rem 0.5rem 1rem 0rem;
    text-align: justify;
    text-justify: inter-word;
  }

  p {
    font-size: smaller;
  }

}


@media (max-width: 640px) {

  .about_body {
    flex-direction: column;
  }

  .about_body:nth-child(2) {
    flex-direction: column-reverse;
  }

  .about_body .about_imga img,
  .about_body .about_imgw img {
    display: block;
    height: 20rem;
    width: 70%;
    margin: 2rem auto;
  }

  .values_body .value h2 {
    font-size: small;
  }

  .compliance_body .compliance_description p,
  .team_body .team_description p {
    padding: 1rem 0rem 1rem 0rem;
    text-align: justify;
    text-justify: inter-word;
  }

  .about_body .about_description1 {
    padding: 1rem 0.5rem 1rem 0rem;
    text-align: justify;
    text-justify: inter-word;
  }

  .compliance_body,
  .team_body {
    padding: 2.5rem 0rem;
  }

  .compliance_body .compliance_head,
  .team_body .team_head {
    font-size: 2rem;
  }

  .compliance_body .compliance_description p,
  .team_body .team_description p {
    padding: 0rem;
  }
  
}
