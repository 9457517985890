.home_card_body {
    padding: 5% auto;
}

.home_cards {
    padding: 4% 0%;
}

.home_cards_head {
    text-align: center;
}

.home_card {
    margin: 4%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.home_card .overlay {
    background-color: #fff;
}

.home_card .imgs {
    height: 15rem;
    background-color: #fff;
}

.home_card .imgp {
    display: block;
    margin: 0% auto;
    height: 15rem;
    width: auto;
    background-color: #fff;
}

.home_card .card-body {
    padding-bottom: 0rem;
}

.home_card .card {
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 2px;
    width: 300px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .15), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    margin: 0rem;
    border-radius: 5px;
}
.card .card-title h1 {
    margin: 0;
    padding: 0.75rem;
    padding-bottom: 0rem;
    font-size: 1.5rem;
    text-align: center;
}

.card .card-image {
    height: 150px;
    background-color: #b5add6;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.home_card .card_btn:hover {
    text-decoration: none;
    background-color: rgb(192, 208, 208);
}

.home_cards .main_button {
    position: relative;
    padding: 0.5rem 1.5rem;
    font-size: medium;
    font-weight: 400;
    border-radius: 20px;
    margin:0 auto;
    display:block;
}

.home_card_body .fixed-bg {
    margin: 4% 0%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100%; 
    width: 100%;
    color: white;
    text-align: center;
    display: table;
}

.home_card_body .customers {
    position: relative;
    display: flex;
    padding: 2rem;
    justify-content: center;
    flex-wrap: wrap;
    background: rgba(0,0,0,0.70);
}

.home_card_body .customers > * {
    text-align: center;
    color: #fff;
    flex: 1 1 20%;
    margin: 1rem;
    padding: 1rem;
}


  @media (max-width: 1007px) {

    .home_card .card {
        width: 250px;
    }

    .customers .numbers h1 {
        font-size: 2.5rem;
    }

    .customers .numbers h3 {
        font-size: 1.5rem;
    }

}
  



  @media (max-width: 640px) {

    .customers .numbers h1 {
        color: #fff;
    }

    .home_cards_head {
        font-size: 2rem !important;
    }

    .home_cards {
        padding-bottom: 2rem;
    }

    .home_card {
        margin-bottom: 1.5rem;
    }
    
    .card .card-title {
        margin: 0;
        padding: 0.25rem;
        padding-bottom: 0rem;
        font-size: 1.5rem;
        text-align: center;
    }

    .home_card .card-body {
        padding: 0rem !important;
        padding-top: 0.75rem !important;
    }

    .card .card-img-top {
        height: 10rem !important;
    }

    .home_card .card-footer {
        padding: 0.5rem 1rem !important;
    }

    .home_card_body .customers {
        padding: 1.5rem;
    }
    
    .home_card_body .customers > * {
        margin: 0.5rem;
        padding: 0.5rem;
    }
}

  