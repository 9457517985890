.mission_vision_body {
  background-color: rgb(248, 232, 222);
}

.mission_vision_body .mission,
.mission_vision_body .vision {
  margin: 2% 5% 0% 5%;
  padding-top: 4%;
  display: flex;
  flex-wrap: wrap;
}

.mission_vision_body .mission > *,
.mission_vision_body .vision > * {
  flex: 1 1 50%;
}

.mission .mission_img img {
  display: block;
  height: 15rem;
  width: 60%;
  margin: 5rem auto;
}

.vision .vision_img img {
  display: block;
  height: 10rem;
  width: 50%;
  margin: 5rem auto;
}

.mission .mission_description,
.vision .vision_description {
  padding: 2rem 2rem 2rem 0rem;
  font-size: 1.2rem;
}


@media (max-width: 1007px) {

  .mission .mission_head,
  .vision .vision_head {
    font-size: 2rem; 
    margin: 0rem auto;
    text-align: center;
  }

  .mission .mission_description,
  .vision .vision_description {
    padding: 1rem 0.5rem 1rem 0rem;
    text-align: justify;
    text-justify: inter-word;
    font-size: 1.2rem;
  }

  .mission .mission_img img {
    display: block;
    height: 15rem;
    width: 70%;
    margin: 4rem auto;
  }

  .vision .vision_img img {
    display: block;
    height: 10rem;
    width: 60%;
    margin: 4rem auto;
  }

}


@media (max-width: 640px) {

  .mission_vision_body .mission,
  .mission_vision_body .vision {
    flex-direction: column;
  }

  .mission_vision_body .mission:nth-child(2),
  .mission_vision_body .vision:nth-child(2) {
    flex-direction: column-reverse;
  }

  .mission .mission_img img {
    display: block;
    height: 20rem;
    width: 70%;
    margin: 2rem auto;
  }

  .vision .vision_img img {
    display: block;
    height: 20rem;
    width: 70%;
    margin: 2rem auto;
  }
  
}